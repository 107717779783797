import { Controller } from '@hotwired/stimulus'

import 'timepicker/jquery.timepicker'

export default class extends Controller {
  connect () {
    $(this.element).timepicker({
      timeFormat: 'g:i a',
      scrollDefault: '8:00 am'
    })
  }

  disconnect () {
    $(this.element).timepicker('remove')
  }
}
