import { Controller } from '@hotwired/stimulus'
import { View, Director } from '@millicast/sdk'

export default class extends Controller {
  static targets = [
    'status',
    'remoteView',
    'liveStatus',
    'bitRate',
    'frameRate',
    'packageLost',
    'viewerCount',
    'startButton',
    'activeStreamingButtonGroup'
  ]

  static values = {
    streamName: String,
    streamAccountId: String
  }

  connect () {
    this.millicastView = this.createStreamingInstance()
    this.connectToStream()
  }

  disconnect () {
    this.millicastView.stop()
    this.millicastView.webRTCPeer.stopStats()
    this.millicastView.webRTCPeer.removeAllListeners('stats')
  }

  logStats () {
    this.millicastView.webRTCPeer.initStats()
    this.millicastView.webRTCPeer.on('stats', (stats) => {
      const video = stats.video?.inbounds?.[0]
      const bitrate = video?.bitrate || 0 // bits per second
      const frameRate = video?.framesPerSecond || 0
      const packageLost = video?.totalPacketsLost || 0

      // When we are streaming Axis, we will set the stats.
      // But if we are streaming dock cameras, we will not set the stats (We will add stats for dock later, but for now, we will not set the stats)
      // So we need the "optional targets", the bellow code is based on https://stimulus.hotwired.dev/reference/targets#:~:text=with%20both%20checkboxes.-,%EF%B9%9F,-Optional%20Targets
      if (this.hasBitRateTarget) {
        this.bitRateTarget.textContent = Math.round(bitrate / 1000)
      }

      if (this.hasFrameRateTarget) {
        this.frameRateTarget.textContent = frameRate
      }

      if (this.hasPackageLostTarget) {
        this.packageLostTarget.textContent = packageLost
      }
    })
  }

  async connectToStream () {
    try {
      await this.millicastView.connect({
        events: [
          'active',
          'inactive',
          'stopped',
          'viewercount'
        ]
      })

      this.logStats()
    } catch (e) {
      if (this.hasStatusTarget) {
        this.statusTarget.textContent = 'Video not currently streaming.'
      }

      if (this.hasLiveStatusTarget) {
        this.liveStatusTarget.textContent = 'OFFLINE'
      }
      this.hideBroadcastComponents()
    }
  }

  createStreamingInstance () {
    const tokenGenerator = () => Director.getSubscriber({
      streamName: this.streamNameValue,
      streamAccountId: this.streamAccountIdValue
    })

    const millicastView = new View('yourStreamName', tokenGenerator, this.remoteViewTarget)

    const activeSources = new Set()

    millicastView.on('broadcastEvent', (event) => {
      const { name, data } = event
      if (this.hasStatusTarget) {
        switch (name) {
          // Publishing
          case 'active':
            activeSources.add(data.sourceId)
            this.showBroadcastComponents()
            this.statusTarget.textContent = ''
            if (this.hasLiveStatusTarget) {
              this.liveStatusTarget.textContent = 'LIVE'
            }
            break
          // Not publishing
          case 'inactive':
            activeSources.delete(data.sourceId)
            this.hideBroadcastComponents()
            if (activeSources.size === 0) {
              this.statusTarget.textContent = 'Video not currently streaming.'
            }

            if (this.hasLiveStatusTarget) {
              this.liveStatusTarget.textContent = 'OFFLINE'
            }

            break
          case 'viewercount':
            if (this.hasViewerCountTarget) {
              this.viewerCountTarget.textContent = `(${data.viewercount})`
            }
            break
          default:
            break
        }
      }
    })

    return millicastView
  }

  showBroadcastComponents () {
    // Axis camera is now being streamed to Dolby, user can play the video or restart the video
    if (this.hasActiveStreamingButtonGroupTarget && this.hasStartButtonTarget) {
      this.activeStreamingButtonGroupTarget.classList.remove('d-none')
      this.activeStreamingButtonGroupTarget.classList.add('row')
      this.startButtonTarget.classList.add('d-none')
    }
  }

  hideBroadcastComponents () {
    // Axis camera is not being streamed to Dolby
    if (this.hasActiveStreamingButtonGroupTarget && this.hasStartButtonTarget) {
      this.activeStreamingButtonGroupTarget.classList.remove('row')
      this.activeStreamingButtonGroupTarget.classList.add('d-none')
      this.startButtonTarget.classList.remove('d-none')
    }
  }
}
